import React, { useState } from "react";
import { Button, Checkbox, SecondaryButton, toast } from "@jammable/ui-core";
import { Input } from "@jammable/ui-components/Forms";
import logo from "../assets/logo.png";
import { FcGoogle } from "react-icons/fc";
import Image from "next/image";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  getAuth,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
} from "firebase/auth";
import axios from "axios";
import { useAuth } from "../context/AuthProvider";
import { useRouter } from "next/router";
import firebase_app from "../utils/firebase";
import {
  customGoogleErrorMessages,
  customSignInErrorMessages,
  customSignUpMessages,
} from "../utils/tools";
import Link from "next/link";
import { Spinner } from "@jammable/ui-components/Loader";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";

const LoginPage = ({ clientIp }) => {
  const [error, setError] = useState(null);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, setUser, updateUser } = useAuth();
  const auth = getAuth(firebase_app);

  const router = useRouter();
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email("Invalid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const height = "tw-h-12 md:tw-h-14";
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // Handle your login logic here
      setLoading(true);
      signInWithEmailAndPassword(auth, values.email.trim(), values.password)
        .then(() => {
          router.push("/");
          toast.success("Welcome back!");
        })
        .catch((error) => {
          setError(customSignInErrorMessages[error.code] || error.message);
        })
        .finally(() => {
          setLoading(false);
        });
      // Redirect or update state as needed
    },
  });

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);

      const details = getAdditionalUserInfo(result);
      if (!details) {
        await result.user.delete();
        toast.error("An error occurred during registration. Please try again.");
        throw new Error("Additional user information not available");
      }

      if (details?.isNewUser) {
        axios
          .post("/api/auth/register", {
            ip: clientIp,
            user: { ...result?.user, username: result?.user?.displayName },
          })
          .then(async (res) => {
            updateUser({
              avatar: res?.data?.user?.avatar,
              username: res?.data?.user?.username,
              subscriptions: [],
              credits: 0,
            });
            try {
              if (window.tolt_referral) {
                window.tolt.signup(res?.data?.user?.email);
              }
            } catch (error) {}
            toast.success("Welcome to Jammable!");
            router.push("/");
          })
          .catch(async (err) => {
            if (!err?.response?.data?.isDeleted) {
              await result.user.delete();
            }
            if (err.response.data.message) {
              setError(err.response.data.message);
            } else {
              toast.error(
                "An error occurred during registration. Please try again.",
              );
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.success("Welcome back!");
        router.push("/");
      }
    } catch (error) {
      setError(customGoogleErrorMessages[error.code] || error.message);
      setLoading(false);
    }
  };

  const inputStyle =
    "tw-w-full tw-rounded-2xl tw-transition-all tw-h-12 md:tw-h-14 tw-text-sm md:tw-text-base tw-bg-gray-50 tw-border-gray-100 tw-border-2 tw-border-solid focus:tw-border-gray-200";

  const errorStyle =
    "tw-text-red-400 tw-font-bold tw-text-sm tw-mt-1 tw-text-center";
  return (
    <div className="t-w-full tw-pt-20 md:tw-pt-4 md:tw-h-screen tw-flex tw-items-center tw-bg-gray-50/80 tw-justify-center tw-p-4">
      <div className="tw-flex-col tw-items-center tw-gap-2 tw-flex tw-bg-white tw-p-6 md:tw-p-8 tw-py-6 tw-rounded-4xl tw-w-full tw-max-w-lg  tw-border tw-border-solid tw-border-gray-100 tw-shadow-md">
        <Image
          quality={100}
          width={65}
          height={65}
          src={logo}
          alt="Jammable Logo"
        />
        <div className="tw-font-black tw-text-2xl md:tw-text-2xl md:tw-py-2 tw-tracking-tight tw-text-center">
          Welcome back!
        </div>
        <div className="tw-w-full">
          <SecondaryButton
            onClick={() => {
              handleGoogleSignIn();
            }}
            className={`tw-w-full  ${height} tw-rounded-full`}
          >
            <FcGoogle size={25} />
            Sign in With Google
          </SecondaryButton>
        </div>
        <div className="tw-font-black tw-text-sm tw-py-1 md:tw-py-2 tw-gap-3 tw-tracking-widest tw-w-full tw-flex tw-items-center tw-text-center tw-justify-center">
          <div className="tw-bg-gray-100 tw-h-0.5 tw-w-full" />
          OR
          <div className="tw-bg-gray-100 tw-h-0.5 tw-w-full" />
        </div>
        <form
          onChange={() => {
            setError(null);
          }}
          onSubmit={formik.handleSubmit}
          className="tw-flex tw-flex-col tw-gap-3 tw-w-full"
        >
          {error && (
            <div className="tw-bg-red-500 tw-flex tw-p-3 tw-text-white tw-font-black tw-text-center tw-rounded-2xl tw-justify-center">
              {error}
            </div>
          )}

          <div>
            <Input
              className={inputStyle}
              placeholder="Email address..."
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && !!formik.errors.email}
              onBlur={formik.handleBlur}
            />
            <div>
              {formik.touched.email && formik.errors.email && (
                <div className={errorStyle}>{formik.errors.email}</div>
              )}
            </div>
          </div>
          <div>
            <Input
              className={inputStyle}
              placeholder="Password..."
              type="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && !!formik.errors.password}
              onBlur={formik.handleBlur}
            />
            <div>
              {formik.touched.password && formik.errors.password && (
                <div className={errorStyle}>{formik.errors.password}</div>
              )}
            </div>
          </div>
          <Link
            href={`/forgotpassword`}
            className="tw-flex tw-cursor-pointer tw-gap-2 tw-font-bold tw-text-purple-700 tw-items-center tw-text-sm "
          >
            Forgot password?
          </Link>
          <Button
            type="submit"
            className="md:tw-mt-6 tw-mt-2 md:tw-h-14 tw-h-12 tw-rounded-full "
            disabled={loading}
          >
            {loading ? (
              <Spinner className="tw-border-white tw-border-3" size={4} />
            ) : (
              "Sign in!"
            )}
          </Button>
        </form>
        <div className="tw-font-semibold tw-mt-1 tw-text-center tw-text-sm ">
          Don&apos;t have an account?{" "}
          <Link
            className="tw-text-purple-700 tw-underline tw-font-black"
            href={`/signup`}
          >
            Sign up for free!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

export async function getServerSideProps(context) {
  const { req } = context;

  const getClientIp = (req) => {
    const ip =
      (req.headers["x-forwarded-for"] || "").split(",").pop().trim() ||
      req.connection.remoteAddress ||
      req.socket.remoteAddress ||
      req.connection.socket.remoteAddress;
    return ip;
  };

  try {
    const clientIp = getClientIp(req);
    return {
      props: {
        clientIp,
      },
    };
  } catch (error) {
    return {
      props: {
        a: "a",
      },
    };
  }
}
